/* Veranstaltungsseite
Aufbau:
divClass: siehe layout.css


*/
import React from 'react'
import {useIntl} from "gatsby-plugin-intl"
import {  graphql } from 'gatsby'
import Layout from '../../components/layout'
import Header from '../../components/header'
import InfoLine from '../../components/infoline'
/* css */
import styles from '../../components/boxUmrandet.module.css'
/* pdf upload*/
/* das PDF muss in dem Verzeichnis hochgeladen sein */
import veranstaltungsflyer1 from '../../downloads/flyer-ficotex-3D-Druck-InfoVA-11-20.pdf'



const ProductPage = (props) => { 
const intl = useIntl()
  return(
  <Layout>
    
    <Header
       /* title= in dem blauen Querbalken */
      title={intl.formatMessage({ id: "va.Ueberschrift-1" })}

      text={intl.formatMessage({ id: "va.Ueberschrift-2" })}
    />
    {/* title= in dem blauen Querbalken */}
    <div className='row'>
      <div className='col-2'>

      </div>
      <div className='col-8'>
      <p> {intl.formatMessage({ id: "va.Ueberschrift-3" })}
      </p>
      </div>
    </div>
{/* ein Informationsblock für eine Veranstaltung */}
      <InfoLine
      distanceLeft='col-2'
      >
        <div className={styles.veranstaltungen}>  
        {/* Überschrift */}
          <h4>{intl.formatMessage({ id: "index.VaUe1-201119" })}</h4>
          {/* Datum / Zeit */}
          <h5> {intl.formatMessage({id: "index.VaUeDatum-201119"})}  </h5>
          {/* ort */}
          <h5> {intl.formatMessage({id: "index.VaUeOrt-201119"})}  </h5>
          {/* Einleser */}
          <p className={styles.ort}> {intl.formatMessage({id: "index.VaUe3-201119"})}  </p>

          <h5> {intl.formatMessage({ id: "va.Programm" })}</h5>

          <p> {intl.formatMessage({ id: "va.Programm-1-Va201129" })}</p>
          <p> {intl.formatMessage({ id: "va.Programm-2-Va201129" })}</p>
          <p> {intl.formatMessage({ id: "va.Programm-3-Va201129" })}</p>
          <p> {intl.formatMessage({ id: "va.Programm-4-Va201129" })}</p>
          <p> {intl.formatMessage({ id: "va.Programm-5-Va201129" })}</p>
          <p> {intl.formatMessage({ id: "va.Programm-6-Va201129" })}</p>
          <p> {intl.formatMessage({ id: "va.Leerzeile" })}</p>  
          <h5> {intl.formatMessage({ id: "va.Corona" })}</h5>    
          <p> {intl.formatMessage({ id: "index.VaCorona" })}</p>
          <p> {intl.formatMessage({ id: "va.Leerzeile" })}</p>  
          <h5> {intl.formatMessage({ id: "va.Anmeldung" })}</h5>  

          <p> {intl.formatMessage({ id: "global.IstFicotex" })}</p>     
          <p> {intl.formatMessage({ id: "global.KompanieStrasse" })}</p>  
          <p> {intl.formatMessage({ id: "global.Werlte" })}</p>  
          <p> {intl.formatMessage({ id: "global.TelefonWerlte" })}</p>     
          <p> {intl.formatMessage({ id: "global.MailInfo" })}</p>       

         {/* Flyer pdf upload */}
         <h5> {intl.formatMessage({ id: "va.Flyer" })}</h5>
         <p> {intl.formatMessage({ id: "va.InfoFlyer" })}</p>  
         <a href={veranstaltungsflyer1} target="_blank"  rel="noreferrer" >Download Flyer</a> 
        
        </div>

      </InfoLine>
  {/* ende */}

  {/*
      <InfoLine
      distanceLeft='col-2'
      >
        <div className={styles.veranstaltungen}>  
          <h5>{intl.formatMessage({ id: "index.VaUe2-201119" })}</h5>
          <p className={styles.ort}> {intl.formatMessage({id: "index.VaUeDatum-201119"})}  </p>
          <p className={styles.ort}> {intl.formatMessage({id: "index.VaUeOrt-201119"})}  </p>
          
          <p> {intl.formatMessage({ id: "va.Text2-Va201129" })}</p>


        </div>
      </InfoLine>



      <InfoLine
      distanceLeft='col-2'
      >
        <div className={styles.veranstaltungen}>  
          <h5>{intl.formatMessage({ id: "index.VaUe3-201119" })}</h5>
          <p className={styles.ort}> {intl.formatMessage({id: "index.VaUeDatum-201119"})}  </p>
          <p className={styles.ort}> {intl.formatMessage({id: "index.VaUeOrt-201119"})}  </p>
          <p> {intl.formatMessage({ id: "va.Text3-Va201129" })}</p>
        </div>
      </InfoLine>
       
      */}
      

    </Layout>
)
}


export default ProductPage;

//fluidImage is defined on imagePage
export const pageQuery = graphql`
  query {

    imageOne: file(relativePath: { eq: "CAD-Zeichnungen-RK-1.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "foto.png" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "CAD-Zeichnungen-RK-1.jpg" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "foto.png" }) {
      ...fluidImage
    }

  }
`