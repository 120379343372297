import React from 'react'
import Link from 'gatsby-link';
import styles from './boxUmrandet.module.css'

/**
Input

title 
text

optional
link: auf welche Seite wird gezeigt
linkText: was soll als Linktext angezeigt werden

*/

const BoxUmrandet = props => (
    
    <div className={styles.line} >
        <div className={props.length}>
            <div >
                <h4 style={{
                    marginBottom:'0.4rem'
                }}>   { props.title } </h4>

                <h5 style={{
                    color:'rgb(145,145,145)'
                }}> { props.subtitle }</h5>
            
                <p> { props.text } </p>
                {props.children}
               
                <div>
                    {props.link  &&
                    <div className={styles.link }>
                        <Link to={props.link}> {props.linkText} </Link>
                    </div>
                    }
                </div>
            </div>

        </div>
    </div> 
)

export default BoxUmrandet;