import React from 'react'
import BoxUmrandet from '../components/boxUmrandet'
/*
Input: 
Title 
subtitle 
text
link
linkText
distance-left: Abstand zu linkem Rand, z.B 'col-3', mittig ist: 'col-2'
*/

const InfoLine = props => (
    
    <div className='row'>
 
            <div className={props.distanceLeft}></div>

            <div className='col-8'>
                <BoxUmrandet 
                    title = {props.title}
                    subtitle = {props.subtitle}
                    text = {props.text}
                    link = {props.link}
                    linkText = {props.linkText}
                >
                    {props.children}
                </BoxUmrandet>
            </div>

      
    </div>
    
    
)


export default InfoLine;