import PropTypes from 'prop-types'
import React from 'react'

const Header = ( {title, text} ) => (
  <div 
        style={{
          backgroundColor: 'rgb(72, 105, 122)',
          color: 'rgb(255,255,255)',
          marginBottom: '1.45rem',
        }}
    >
      <div
      style={{
        margin: '0 auto',
        maxWidth: 1200,
        padding: '2rem 1.0875rem 0.5rem',
    
      }}
      >
        <center>
        <h1 style={{
          marginBottom: '0.8rem'
        }}>{title}</h1>
       
        <h3> {text}  </h3>
        </center>
      </div>
    </div>
 
)

Header.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

Header.defaultProps = {
  title: ``,
  text: ``,
}

export default Header
